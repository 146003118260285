.work-steps > div > div {
  height: 15rem;
}

@media (max-width: 882px) {
  .work-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .work-steps > div {
    width: 90%;
    height: 40vh;
    padding-right: 10px;
  }

  .work-steps > div:last-child {
    padding-bottom: 50px;
  }

  .work-steps > div > div {
    margin: 4%;
    bottom: 27%;
    height: 13rem;
  }
}

.attendant-section-container {
  margin-bottom: 5rem;
}

@media (min-width: 994px) {
  .video-container {
    padding-right: 5rem;
    padding-left: 2rem;
  }
}

@media (max-width: 993px) {
  .video-container {
    width: 100% !important;
    box-sizing: border-box;
    padding: 0 1rem !important;
    margin-top: 4rem;
    margin-bottom: 0 !important;
  }

  .attendant-section-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .attendant-section-container {
    margin-top: 2rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
  }

  .attendant-section-container > h4 {
    width: 80%;
  }
}

@media (max-width: 850px) {
  .main-title-with-phones-container > div {
    display: none;
  }

  .main-title-with-phones-container {
    flex-direction: column;
  }

  .main-title-with-phones-container h1 {
    display: block;
    width: 100%;
    margin-left: 0;
    padding: 0 1rem;
    box-sizing: border-box;
  }
}

@media (min-width: 851px) {
  .main-title-with-phones-container {
    margin-bottom: 6rem;
  }
}

.ReactModal__Overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow-y: auto;
  padding: 1rem 0;
}
.ReactModal__Overlay::after,
.ReactModal__Overlay::before {
  content: '';
  width: 0;
  flex: 1 1 0;
}

.ReactModal__Content {
  flex: none;
  left: auto !important;
  right: auto !important;
  position: relative !important;
  max-height: 95vh;
}
