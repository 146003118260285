body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Custom sea videojs theme */
.vjs-theme-sea * {
  outline: none;
  box-shadow: none;
}

.vjs-theme-sea .vjs-big-play-button {
  width: 103px;
  height: 79px;
  object-fit: contain;
  border: none;
  margin: 0 auto;
  line-height: 79px;
  top: 50%;
  left: 50%;
  margin-top: -51.5px;
  margin-left: -39.5px;
  color: #ffffff;
  background-color: #000000;
}

.vjs-theme-sea:hover .vjs-big-play-button,
.vjs-theme-sea .vjs-big-play-button:hover {
  background-color: #000000;
  color: #3fd03f;
}

.vjs-theme-sea .vjs-control-bar {
  height: 4em;
  background-color: #000;
}

.vjs-theme-sea .vjs-button:hover {
  color: #32cd32;
}

.vjs-theme-sea .vjs-button > .vjs-icon-placeholder::before {
  line-height: 2.2;
}

.vjs-theme-sea .vjs-time-control {
  line-height: 4em;
}

.vjs-theme-sea .vjs-volume-panel {
  display: none;
}

.vjs-theme-sea .vjs-picture-in-picture-control {
  display: none;
}

.vjs-theme-sea .vjs-progress-control .vjs-play-progress {
  background-color: #32cd32;
}

.vjs-theme-sea .vjs-progress-control .vjs-play-progress:before {
  display: none;
}

.vjs-theme-sea .vjs-progress-control .vjs-slider {
  background-color: transparent;
}

.vjs-theme-sea .vjs-progress-control .vjs-load-progress div {
  background: #9ce79c;
}

.vjs-theme-sea .vjs-progress-control .vjs-progress-holder {
  margin: 0;
  height: 100%;
}
